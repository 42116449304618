const PrivacyPolicy = () => {
    return (
        <>
            <div>
                <div>
                    <article className="text-center">
                  


                        <p className="font-bold text-3xl mb-4">
                            PRIVACY NOTICE</p>

                        <p className="font-bold mb-4">Last updated</p>

                        

                        <p>This privacy notice for XXXXXXXX (&lsquo;Company&rsquo;, &lsquo;we&rsquo;, &lsquo;us&rsquo;, or &lsquo;our&rsquo;,), describes how and why we might collect, store, use, and/or share (&lsquo;process&rsquo;) your information when you use our services (&lsquo;Services&rsquo;), such as when you:</p>

                        <p>Visit our website, or any website of ours that links to this privacy notice.</p>

                        <p>Download and use our mobile application, our Facebook application, or any other application of ours that links to this privacy notice.</p>

                        <p>Engage with us in other related ways, including any sales, marketing, or events.</p>

                        <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact XXXXXXXXXX us at XXXXXXXXX</p>

                        <p>SUMMARY OF KEY POINTS</p>

                        <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click here to go directly to our table of contents.</p>

                        <p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with XXXXXXXXX and the Services, the choices you make, and the products and features.<br />
                            you use. Click here to learn more.</p>

                        <p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>

                        <p>Do we receive any information from third parties? We do not receive any information from third parties.</p>

                        <p>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click here to learn more.</p>

                        <p>In what situations and with which types of parties do we share personal information? We may share information in specific situations and with specific categories of third parties. Click here to learn more.</p>

                        <p>How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information,<br />
                            Click here to learn more.</p>

                        <p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click here to learn more.</p>

                        <p>How do you exercise your rights? The easiest way to exercise your rights is by filling out our data subject request form available here, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>

                        <p>Want to learn more about what xxxxxx does with any information we collect? Click here to review the notice in full.</p>

                        <p className="font-bold mb-4 mt-2">TABLE OF CONTENTS</p>

                        <p className="font-bold mb-4 mt-2">1. WHAT INFORMATION DO WE COLLECT?<br />
                            2. HOW DO WE PROCESS YOUR INFORMATION?<br />
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?<br />
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<br />
                            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br />
                            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?<br />
                            7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?<br />
                            8. HOW LONG DO WE KEEP YOUR INFORMATION?<br />
                            9. HOW DO WE KEEP YOUR INFORMATION SAFE?<br />
                            10. DO WE COLLECT INFORMATION FROM MINORS?<br />
                            11. WHAT ARE YOUR PRIVACY RIGHTS?<br />
                            12. CONTROLS FOR DO-NOT-TRACK FEATURES<br />
                            13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?<br />
                            14. DO WE MAKE UPDATES TO THIS NOTICE?<br />
                            15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?<br />
                            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>

                        <p className="font-bold mb-4 mt-2">1. WHAT INFORMATION DO WE COLLECT?</p>

                        <p>Personal information you disclose to us</p>

                        <p>In Short: We collect personal information that you provide to us.</p>

                        <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                        <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

                        <p> &bull; names<br />
                            &bull; email addresses<br />
                            &bull; passwords</p>

                        <p>Sensitive Information. We do not process sensitive information.</p>

                        <p>Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS below.</p>

                        <p>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission</p>

                        <p>Geolocation Information - We may request access or permission to track location-based information from your mobile device, while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&rsquo;s settings.</p>

                        <p>Mobile Device Access - We may request access or permission to certain features from your mobile device, including your mobile device's bluetooth, camera, contacts, microphone, calendar. reminders, sensors, ems messages. social media accounts, storage, and other features. if you wish to change our access or permissions, you may do so in your device's settings.</p>

                        <p>Mobile Device Data - We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device's operating system or platform, the type of mobile device you use, your mobile device's unique device ID, and information about the features of our application(s) you accessed.<br />
                            Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s), If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings. This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>

                        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                        <p>Information automatically collected</p>

                        <p>In Short: Some information &mdash; such as your Internet Protocol (Ip) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</p>

                        <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analyties and reporting purposes.</p>

                        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>

                        <p>The information we collect includes:<br />
                            <br />
                            &bull; Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers atomatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</p>

                        <p> &bull; Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carriee operating system, and system configuration information.</p>

                        <p> &bull; Location Data. We collect location data such as informs<br />
                            device's location, which can be either precise or imprecise information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>

                        <p>Information collected when you use our Facebook application(s). We by default<br />
                            access your Facebook basic account information, including your name, email,<br />
                            gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, check ins and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook permissions, refer to the Facebook Permissions<br />
                            Reference page.</p>

                        <p className="font-bold mb-4 mt-2">
                            2. HOW DO WE PROCESS YOUR INFORMATION?</p>

                        <p>In Short: We process your information to provide, improve, and administer our<br />
                            Services, communicate with you, for security and fraud prevention, and to comply<br />
                            with law. We may also process your information for other purposes with your consent.</p>

                        <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>

                        <p>To facilitate account creation and authentication and otherwise manage<br />
                            user accounts. We may process your information so you can create and log<br />
                            in to your account, as well as keep your account in working order.</p>

                        <p>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</p>

                        <p>To respond to user inquiries/offer support to users. We may process your<br />
                            information to respond to your inquiries and solve any potential issues you<br />
                            might have with the requested service.</p>

                        <p>To send administrative information to you. We may process your<br />
                            information to send you details about our products and services, changes to<br />
                            our terms and policies, and other similar information.</p>

                        <p>To fulfil and manage your orders. We may process your information to fulfil<br />
                            and manage your orders, payments, returns, and exchanges made through the<br />
                            Services.</p>

                        <p>To enable user-to-user communications. We may process your information<br />
                            if you choose to use any of our offerings that allow for communication with<br />
                            another user.</p>

                        <p>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>

                        <p>To send you marketing and promotional communications. We may<br />
                            process the personal information you send to us for our marketing purposes, if<br />
                            this is in accordance with your marketing preferences. You can opt out of our<br />
                            marketing emails at any time. For more information, see 'WHAT ARE YOUR<br />
                            PRIVACY RIGHTS?' below).</p>

                        <p>To deliver targeted advertising to you. We may process your information to<br />
                            develop and display personalised content and advertising tailored to your<br />
                            interests, location, and more.</p>

                        <p>To protect our Services. We may process your information as part of our<br />
                            efforts to keep our Services safe and secure, including fraud monitoring and<br />
                            prevention.</p>

                        <p>To identify usage trends. We may process information about how you use<br />
                            our Services to better understand how they are being used so we can improve<br />
                            them.</p>

                        <p>To determine the effectiveness of our marketing and promotional<br />
                            campaigns. We may process your information to better understand how to<br />
                            provide marketing and promotional campaigns that are m</p>

                        <p>To save or protect an individual's vital interest. We information when necessary to save or protect an individual.</p>

                        <p className="font-bold mb-4 mt-2">
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</p>

                        <p>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests,</p>

                        <p>If you are located in the EU or UK, this section applies to you</p>

                        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>

                        <p>Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click here to learn more.</p>

                        <p>Performance of a Contract We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</p>

                        <p>Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</p>

                        <p>Send users information about special offers and discounts on our products and services</p>

                        <p>Develop and display personalised and relevant advertising content for our users</p>

                        <p>Analyse how our services are used so we can improve them to engage and retain users</p>

                        <p>Support our marketing activities</p>

                        <p>Diagnose problems and/or prevent fraudulent activities</p>

                        <p>Understand how our users use our products and services so we can improve user experience</p>

                        <p>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation) in which we are involved.</p>

                        <p>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</p>

                        <p>In legal terms, we are generally the 'data controller' under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This privacy notice does not apply to the personal information we process as a 'data processor' on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the data controller responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices you should read their privacy policies and direct any questions you have to them.</p>

                        <p>If you are located in Canada, this section applies to you.</p>

                        <p>We may process your information if you have given us specific permission (i.e.express consent) to use your personal information for a specific situations where your permission can be inferred (i.e. implied withdraw your consent at any time. Click here to learn more.</p>

                        <p>In some exceptional cases, we may be legally permitted under applicable law to<br />
                            process your information without your consent, including, for example:</p>

                        <p>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</p>

                        <p>For investigations and fraud detection and prevention</p>

                        <p>For business transactions provided certain conditions are met</p>

                        <p>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</p>

                        <p>For identifying injured, ill, or deceased persons and communicating with next of kin</p>

                        <p>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</p>

                        <p>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a<br />
                            contravention of the laws of Canada or a province</p>

                        <p>If disclosure is required to comply with a subpoena, warrant, court order, o<br />
                            rules of the court relating to the production of records</p>

                        <p>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</p>

                        <p>If the collection is solely for journalistic, artistic, or literary purposes</p>

                        <p>If the information is publicly available and is specified by the regulations</p>

                        <p className="font-bold mb-4 mt-2">4. WHEN AND WITH WHOM DO WE SHARE YOURPERSONAL INFORMATION?</p>

                        <p>In Short: We may share information in specific situations described in this section<br />
                            and/or with the following categories of third parties.</p>

                        <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share<br />
                            your data with third party vendors, service providers, contractors, or agents (&lsquo;third parties&rsquo;) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it.</p>

                        <p>They will also not share your personal information with any organisation apart<br />
                            from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:</p>

                        <p>Ad Networks</p>

                        <p>Affiliate Marketing Programs</p>

                        <p>Cloud Computing Services</p>

                        <p>Communication &amp; Collaboration Tools</p>

                        <p>Data Analytics Services</p>

                        <p>Data Storage Service Providers</p>

                        <p>Finance &amp; Accounting Tools</p>

                        <p>Government Entities</p>

                        <p>Order Fulfilment Service Providers</p>

                        <p>Payment Processors</p>

                        <p>Performance Monitoring Tools</p>

                        <p>Product Engineering &amp; Design Tools</p>

                        <p>Retargeting Platforms</p>

                        <p>Sales &amp; Marketing Tools</p>

                        <p>Social Networks</p>

                        <p>Testing Tools</p>

                        <p>User Account Registration &amp; Authentication Services</p>

                        <p>Website Hosting Service Providers</p>

                        <p>
                            We also may need to share your personal information in the following situations:</p>

                        <p>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>

                        <p>Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice, Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</p>

                        <p>Offer Wall. Our application(s) may display a third party hosted &lsquo;offer wall&rsquo;. Such an offer wall allows third party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s), A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</p>

                        <p className="font-bold mb-4 mt-2">
                            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>

                        <p>In Short: We may use cookies and other tracking technologies to collect and store your information.</p>

                        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information, Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>

                        <p className="font-bold mb-4 mt-2">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>

                        <p>In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.<br />
                            <br />
                            Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. If you log in using Facebook, we may also request access to other permissions related to your account, such as your friends, check ins and likes, and you may choose to grant or deny us access to each individual permission,<br />
                            <br />
                            We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third -party social media provider. We recommend that you review their privacy notice to understand how they collect, use, a n d share your personal information, and how you can set your privacy preferences on their sites and apps.</p>

                        <p className="font-bold mb-4 mt-2"> 7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?<br />
                            <br />
                            In Short: We may transfer, store, and process your information in countries other than your own.<br />
                            <br />
                            Our servers are located in xxxxxxxxxxx. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see WHEN AND WITH WHOM <br />
                            DO WE SHARE YOUR PERSONAL INFORMATION?' above), in and other countries,</p>

                        <p>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. <br />
                            However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>

                        <p className="font-bold mb-4 mt-2">8. HOW LONG DO WE KEEP YOUR INFORMATION?</p>

                        <p>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>

                        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

                        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                        <p className="font-bold mb-4 mt-2">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>

                        <p>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</p>

                        <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment,</p>

                        <p className="font-bold mb-4 mt-2">10. DO WE COLLECT INFORMATION FROM MINORS?</p>

                        <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>

                        <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we leam that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18, please contact us at TBA XXXXX.</p>

                        <p className="font-bold mb-4 mt-2"> 
                            11. WHAT ARE YOUR PRIVACY RIGHTS?</p>

                        <p>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>

                        <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i)<br />
                            obtain a copy of your personal information, (ii) to request rectification or erasure. (iii)to restrict the processing of your personal information; (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'HOW <br />
                            CAN YOU CONTACT US ABOUT THIS NOTICE? below.</p>

                        <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>

                        <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:</p>

                        <p>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>

                        <p>If you are located in Switzerland, the contact details for the data protection authorities are available here:</p>

                        <p>https://www.edoeb.admin.ch/edoeb/en/home.html.</p>

                        <p>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or updating your preferences.</p>

                        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                        <p>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE to the SMS messages that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you <br />
                            - for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>

                        <p>Account Information</p>

                        <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

                        <p>Log in to your account settings and update your user account.</p>

                        <p>Contact us using the contact information provided,</p>

                        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and or comply with applicable legal requirements.</p>

                        <p>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.</p>

                        <p className="font-bold mb-4 mt-2">12. CONTROLS FOR DO-NOT-TRACK FEATURES</p>

                        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&lsquo;DNT&rsquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                        <p className="font-bold mb-4 mt-2">13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>

                        <p>In Short: Yes, if you are a resident of California, you are granted specific rights <br />
                            regarding access to your personal information.</p>

                        <p>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year if you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below,</p>

                        <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address<br />
                            associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>

                        <p>CCPA Privacy Notice</p>

                        <p>The California Code of Regulations defines a 'resident' as:</p>

                        <p> (1) every individual who is in the State of California for other than a temporary or transitory purpose and<br />
                            (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</p>

                        <p>All other individuals are defined as 'non-residents'.</p>

                        <p>If this definition of 'resident' applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>

                        <p>What categories of personal information do we collect?</p>

                        <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

                        <p>Category Examples Collected</p>

                        <p>A. Identifiers Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier. online identifier, Internet Protocol address, email address, and account name NO</p>

                        <p>B. Personal information categories listed in the California Customer Records statute Name, contact information, education, employment, employment history, and financial information NO</p>

                        <p>C. Protected classification characteristics under California or federal law Gender and date of birth NO</p>

                        <p>D. Commercial information Transaction information, purchase history, financial details, and payment information NO</p>

                        <p>E. Biometric information Fingerprints and voiceprints NO</p>

                        <p>
                            F. Internet or other similar network activity Browsing history, search history, online behaviour, interest data, and interactions with our and other websites&#8222; applications, systems; and advertisements NO<br />
                            .... :&lsquo;, ,</p>

                        <p>G. Geolocation data Device location e</p>

                        <p>H. Audio, electronic, visual, thermal, olfactory, or similar information Images and audio, video or call recordings created in connection with our business activities NO</p>

                        <p>I. Professional or employment elated information Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us NO</p>

                        <p>J. Education Information Student records and directory information NO</p>

                        <p>K. Inferences drawn from other personal information Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics NO</p>

                        <p>We may also collect other personal information outside of these categories through<br />
                            instances where you interact with us in person, online, or by phone or mail in the<br />
                            context of:</p>

                        <p>Receiving help through our customer support channels.</p>

                        <p>Participation in customer surveys or contests; a n d</p>

                        <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>

                        <p>
                            How do we use and share your personal information?</p>

                        <p>
                            Xxxxxxxxxxxx collects and shares your personal information through:</p>

                        <p>Targeting cookies/Marketing cookies</p>

                        <p>Social media cookies</p>

                        <p>Beacons/Pixels/Tags</p>

                        <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

                        <p>You can opt out from the selling of your personal information by disabling cookies in Cookie Preference Settings and clicking on the Do Not Sell My Personal Information link on our homepage.</p>

                        <p>You may contact us or by referring to the contact details at the bottom of this<br />
                            document.</p>

                        <p>If you are using an authorised agent to exercise your right to opt out we may deny a request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.</p>

                        <p>Will your information be shared with anyone else?</p>

                        <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.<br />
                            We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling&rsquo; of your personal information.<br />
                            Xxxxxxxxx has disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>

                        <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<br />
                            <br />
                            Xxxxxxxxx has sold the following categories of personal infer in the preceding twelve (12) months:</p>

                        <p>The categories of third parties to whom we sold personal information</p>

                        <p>Ad Networks</p>

                        <p>Affiliate Marketing Programs</p>

                        <p>Data Analytics Services</p>

                        <p>Retargeting Platforms</p>

                        <p>Social Networks</p>

                        <p>User Account Registration &amp; Authentication Services</p>

                        <p>Your rights with respect to your personal data</p>

                        <p>Right to request deletion of the data Request to delete</p>

                        <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>

                        <p>Right to be informed &mdash; Request to know</p>

                        <p>Depending on the circumstances, you have a right to know:</p>

                        <p>whether we collect and use your personal information;</p>

                        <p>the categories of personal information that we collect;</p>

                        <p>the purposes for which the collected personal information is used;</p>

                        <p>whether we sell your personal information to third parties;</p>

                        <p>the categories of personal information that we sold or disclosed for a business purpose;</p>

                        <p>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</p>

                        <p>the business or commercial purpose for collecting or selling personal information.</p>

                        <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>

                        <p>Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</p>

                        <p>We will not discriminate against you if you exercise your privacy rights.</p>

                        <p>Verification process</p>

                        <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>

                        <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</p>

                        <p>Other Privacy Rights</p>

                        <p>You may object to the processing of your personal information.</p>

                        <p>You may request correction of your personal data if it is incorrect or no longer relevant or ask to restrict the processing of the information.</p>

                        <p>You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.</p>

                        <p>To exercise these rights, you can contact us or by referring to the contact details at the bottom of this document if you have a complaint about how we handle your data, we would like to hear from you.</p>

                        <p className="font-bold mb-4 mt-2">
                            14. DO WE MAKE UPDATES TO THIS NOTICE?</p>

                        <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

                        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                        <p>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>

                        <p>If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), TBA, by email at TBA, by phone at TBA, or by post to: TBA xxxxxxxx<br />
                            If you are a resident in the United Kingdom, the 'data controller' of your personal information is xxxxxxx has appointed MR N WITTS to be its representative in the UK. You can contact them directly regarding the processing of your information by 01137 303 505 or by post to:<br />
                            United Kingdom</p>

                        <p>16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>

                        <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please submit a request form by clicking here.<br />
                        </p>
                    </article>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy