import React from 'react'
import AboutUS from '../Components/aboutus'
import Footer from '../Components/footer'
import Header from '../Components/hero'
import Howitworks from '../Components/how_it_works'
import OurServices from '../Components/ourservices'
import Reviews from '../Components/testimonals'
import WhatsIncluded from '../Components/whatsIncluded'

const LandingPage = () => {
    return(
        <>
            <div className=''>
                <Header/>
                <Howitworks/>
                <OurServices/>
                <WhatsIncluded/>
                <AboutUS/>
                <Reviews/>
                <Footer/>
            </div>
        </>
    )
}

export default LandingPage