import React from 'react'
import { ImCogs } from 'react-icons/im'


const WhatsIncluded = () => {
    return (
        <>
            <section class="mx-auto px-6 text-gray-800 md:max-w-screen-xl" id='how-it-works'>
                <div class="mx-auto mt-20 mb-16 block px-6 text-center">
                    <h2 class="text-4xl font-bold tracking-tight sm:text-5xl text-black">Whats Included?</h2>
                    <div class="mx-auto mt-6 mb-auto block w-full text-xl font-normal leading-9 text-gray-700 md:w-3/4 xl:w-3/4">
                        <p class="text-lg">Below we detail what is included in our engine reconditioning service.</p>
                    </div>
                </div>

                <div class="grid gap-10 pb-20 sm:grid-cols-1 lg:grid-cols-1">
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                            <div className='flex bg-[#DC143C] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ImCogs color='white' size={28} />
                            </div>
                            <h3 class="text-2xl text-center font-bold md:text-xl">Engine Reconditioning</h3>
                        </div>
                        <ul class="flex flex-col items-center text-center list-disc	">
                            <li>Cylinder head pressure tested for cracks and then refaced</li>
                            <li>Valves, Valve seats, Valve guides, Valve stem seals</li>
                            <li>Hydraulic lifters </li>
                            <li>Camshaft</li>
                            <li>Head gasket</li>
                            <li>Cylinders </li>
                            <li>Pistons</li>
                            <li>Piston rings</li>
                            <li>Con Rod small end bearing</li>
                            <li>Con Rod big end bearing</li>
                            <li>Crankshaft</li>
                            <li>Crankshaft polishing</li>
                            <li>Crankshaft seals</li>
                            <li>Timing chain / belt kit</li>
                            <li>Cylinders honing</li>
                            <li>Main bearings</li>
                            <li>Oil pump</li>
                            <li>Camshaft seals</li>
                        </ul>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WhatsIncluded