import React from 'react'
import engine from '../Assets/Images/engine.webp'

const AboutUS = () => {
    return (
        <>
            <section id='about-us'>
                <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div class="">
                        <h2 class="text-3xl font-bold sm:text-5xl text-center mb-12">
                            About Us.
                        </h2>
                    </div>

                    <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="relative h-64 overflow-hidden sm:h-80 lg:h-full">
                            <img
                                alt="Engine"
                                src={engine}
                                class="absolute inset-0 h-full w-full object-cover rounded-lg"
                            />
                        </div>

                        <div class="lg:py-16">
                            <article class="space-y-4 text-gray-600">
                                <p>
                                Engine Swap specialise in introducing quality engine re-conditioning companies for all makes and models of vehicle
                                </p>

                                <p>
                                Engine Swap offer FREE no obligation quotations, with friendly advisers dedicated to understanding your requirements and introducing you to our approved engine reconditioners. They can rebuild your engine at a fraction of main dealer prices, you are under no obligation to accept any quote. There really isn't any reason not to fill out the form today, your vehicle could be back on the road in no time.
                                </p>
                                <p>
                                Our network consists of a number of quality engine re-conditioners located throughout the United Kingdom, all offering a range of guaranteed engine services. One of our registered suppliers will have the parts in stock to offer a competitive price with a comprehensive warranty.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUS