import React, { useState, useRef } from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import engine from '../Assets/Images/engine.webp'
import favicon from '../Assets/Images/favicon.webp'
import Swal from 'sweetalert2'
import ClipLoader from "react-spinners/ClipLoader";


const navigation = [
  { name: 'Home', href: '#home' },
  { name: 'How it works', href: '#how-it-works' },
  { name: 'About us', href: '#about-us' },
  { name: 'Reviews', href: '#reviews' },
]

const Header = () => {


  const [showModal, setShowModal] = useState(false);

  const form = useRef();


  const [name, setName] = useState('')
  const [postCode, setPostCode] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [email, setEmail] = useState('')
  const [customerCarRegistration, setCustomerCarRegistration] = useState('')
  const [nearestCity, setnearestCity] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [valid, setValid] = useState(true)
  const [validReg, setValidReg] = useState(true)

  const handleClick = (e) => {

    const checkReg = async () => {

      setIsLoading(true)
      setValidReg(true)
      setValid(true)

      try {
        const response = await fetch('https://determined-duck-buckle.cyclic.app/api/getDetails/' + customerCarRegistration)
          .then((res) => res.json())
          .then((data) => {
            if (data.model == '') {
              setIsLoading(false)
              setValidReg(false);
            } else {
              saveJob(e)
            }
          })
      }
      catch (error) {
        console.log(error)
        setIsLoading(false)
        return
      }
    }

    checkReg()
  };


  const saveJob = async (event) => {

    console.log(event)

    event.preventDefault()


    if (name && postCode && contactNumber && email && nearestCity !== '') {
      try {

        const response = await fetch('https://determined-duck-buckle.cyclic.app/api/createJob', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: `{
                "customerName" : "${name}",
                "customerPhonenumber": "${contactNumber}",
                "customerPostCode": "${postCode}",
                "nearestCity": "${nearestCity}",
                "customerCarRegistration": "${customerCarRegistration}",
                "customerEmail": "${email}"
            }`,
        }).then((res) => res.json()).then(() => Swal.fire({
          title: 'Success!',
          text: 'You job has been successfully posted, A mechanic will reach out to you shortly with quotes',
          icon: 'success',
          confirmButtonText: 'Ok'
        }))
        setIsLoading(false)
        console.log(response)
      } catch (e) {
        console.log(e);
        setIsLoading(false)
      }
    } else {
      setValid(false);
      setIsLoading(false)
    }

  }


  return (
    <div className="relative overflow-hidden bg-white" id='home'>
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
          <svg
            className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    <a href="#home">
                      <span className="sr-only">Engine Swap</span>
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={favicon}
                          alt=""
                        />
                      </div>
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3b9ddd]">
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}

                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={favicon}
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3b9ddd]">
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>

                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-center md:text-center lg:text-start text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Engine Swap</span>{' '}
                <span className="block text-[#42ACF5] xl:inline">UK</span>
              </h1>
              <p className="text-center md:text-left lg:text-left mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-md lg:mx-0">
                Looking for a reconditioned engine. Look no further, at Engine Swap UK we specialise in reconditioned engines. Our network of suppliers can offer a supply & fit service or supply only. Let us know your requirements. 01137 303 505.<br /><span className='text-[#42ACF5] font-bold'>No upfront cost</span> , <span className='text-[#42ACF5] font-bold'>Quality workmanship</span> , <span className='text-[#42ACF5] font-bold'>Vehicle recovery</span> , <span className='text-[#42ACF5] font-bold'>Comprehensive warranty</span>
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow mb-4 md:mr-4 lg:mr-4" onClick={() => setShowModal(true)}>
                  <p
                    className="flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-[#42ACF5] px-8 py-3 text-base font-medium text-white hover:bg-[#3b9ddd] md:py-4 md:px-10 md:text-lg"
                  >
                    Get a quote
                  </p>
                </div>
                <div className="rounded-md ">
                  <a
                    href='tel:01137303505'
                    className="flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-[#42ACF5] px-8 py-3 text-base font-medium text-white hover:bg-[#3b9ddd] md:py-4 md:px-10 md:text-lg"
                  >
                    Contact Us
                  </a>
                </div>
                {showModal ? (
                  <>
                    <div
                      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                      <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                          {/*header*/}
                          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                              Get a quote now
                            </h3>
                            <button
                              className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setShowModal(false)}
                            >
                              <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>
                          {/*body*/}
                          <div onSubmit={() => saveJob()}>
                            <div className="relative p-6 flex-auto">

                              <div>
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                                <input onChange={(e) => setName(e.target.value)} type="name" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Toby Smith" required />
                              </div>
                              <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                                <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@gmail.com" required />
                              </div>
                              <div>
                                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your phone number</label>
                                <input onChange={(e) => setContactNumber(e.target.value)} type="phone" name="phone" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="+447945474559" required />
                              </div>
                              <div>
                                <label for="post" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Post Code</label>
                                <input onChange={(e) => setPostCode(e.target.value)} type="post" name="post" id="post" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="BTXX XXX" required />
                              </div>
                              <div>
                                <label for="city" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Nearest City</label>
                                <input onChange={(e) => setnearestCity(e.target.value)} type="city" name="city" id="city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="London" required />
                              </div>
                              <div>
                                <label for="reg" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your vehicle registration</label>
                                <input onChange={(e) => setCustomerCarRegistration(e.target.value)} type="reg" name="reg" id="reg" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="MNZ XXXX" required />
                              </div>
                            </div>
                            <div className='text-center justify-center'>
                              {isLoading ? <p>Your Request Is Being Processed</p> : null}
                            </div>

                            <div className='text-center'> 
                              {!validReg ? <p className='text-red-500 font-medium w-3/4 pt-5'>Please enter a valid registration number.</p> : null}
                              {!valid ? <p className='text-red-500 font-medium w-3/4 pt-5'>Please ensure all fields has been filled.</p> : null}
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                              <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                              <input disabled={isLoading} onClick={(e) => handleClick(e)} type='submit' value='Get quote now' className='bg-[#3b9ddd] py-2 px-2 rounded-xl text-white uppercase cursor-pointer'></input>
                            </div>
                          </div>
                          {/*footer*/}
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                ) : null}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
          src={engine}
          alt=""
        />
      </div>

    </div>


  )
}

export default Header
