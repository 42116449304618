import React from 'react'
import { ImCogs } from 'react-icons/im'


const OurServices = () => {
    return (
        <>
            <section class="mx-auto px-6 text-gray-800 md:max-w-screen-xl" id='how-it-works'>
                <div class="mx-auto mt-20 mb-16 block px-6 text-center">
                    <h2 class="text-4xl font-bold tracking-tight sm:text-5xl text-black">Our Services.</h2>
                    <div class="mx-auto mt-6 mb-auto block w-full text-xl font-normal leading-9 text-gray-700 md:w-3/4 xl:w-3/4">
                        <p class="text-lg">Though our suppliers cannot offer a one-size-fits-all service, all projects start with the disassembly, cleaning, and evaluation of your engine and its component parts. During the rebuild, they will replace anything that falls below original specification and tolerance, replace the bearings that are in contact with other moving parts, replace piston rings, head gasket, oil and water gaskets. To learn more about Engine Reconditioning services, please contact a member of our team today.</p>
                    </div>
                </div>

                <div class="grid gap-10 pb-20 sm:grid-cols-2 lg:grid-cols-2">
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                            <div className='flex bg-[#02c39a] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ImCogs color='white' size={28} />
                            </div>
                            <h3 class="text-2xl text-center font-bold md:text-xl">Engine Reconditioning & Fitting</h3>
                        </div>
                        <ul class="flex flex-col items-center text-center list-disc	">
                            <li>Vehicle collection</li>
                            <li>Engine Removal</li>
                            <li>Engine Reconditioned</li>
                            <li>Engine Refitment</li>
                            <li className='mb-4'>Ready for collection</li>
                            <p className='font-bold'>**Redelivery available at extra cost**</p>
                        </ul>                    </div>
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                            <div className='flex bg-[#FF8800] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ImCogs color='white' size={28} />
                            </div>
                            <h3 class="text-2xl text-center font-bold md:text-xl">Engine Reconditioning Only</h3>
                        </div>
                        <ul class="flex flex-col text-center items-center list-disc	">
                            <li className='mb-4'>We send you a reconditioned engine with a £300 surcharge</li>
                            <p className='font-bold'>**Surcharge refundable on return of old engine**</p>
                        </ul>     
                    </div>




                </div>
            </section>

        </>
    )
}

export default OurServices