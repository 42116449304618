import React from 'react'
import { BsNewspaper } from 'react-icons/bs'
import { ImCogs } from 'react-icons/im'
import { BsFillCreditCard2BackFill } from 'react-icons/bs'

const Howitworks = () => {
    return (
        <>
            <section class="mx-auto px-6 text-gray-800 md:max-w-screen-xl" id='how-it-works'>
                <div class="mx-auto mt-20 mb-16 block px-6 text-center">
                    <h2 class="text-4xl font-bold tracking-tight sm:text-5xl text-black">How it works.</h2>
                    <div class="mx-auto mt-6 mb-auto block w-full text-xl font-normal leading-9 text-gray-700 md:w-3/4 xl:w-3/4">
                        <p class="text-lg">We match your requirements with an engine supplier who has passed our engine quality assessment for supplying and fitting a re-manufactured / reconditioned engine that suits the specification of your vehicle.Your engine can be fitted by the suppliers experienced mechanics in their own workshops, or delivered to your local garage </p>
                    </div>
                </div>

                <div class="grid gap-10 pb-20 sm:grid-cols-2 lg:grid-cols-3">
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                            <div className='flex bg-[#9672FF] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <BsNewspaper color='white' size={28} />
                            </div>
                            <h3 class="text-2xl font-bold md:text-xl">Fill out our form</h3>
                        </div>
                        <p class="text-gray-700 text-center">If you would like to get started with Engine Swap, Click on the "Get a quote" button above and fill out our simple form.</p>
                    </div>
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                        <div className='flex bg-[#42ACF5] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <BsFillCreditCard2BackFill color='white' size={28} />
                            </div>
                            <h3 class="text-2xl font-bold md:text-xl">Get a quote</h3>
                        </div>
                        <p class="text-gray-700 text-center">Once the form has been submitted one of our specialists will reach out to you with our best possible price for your engine re-manufacture / reconditioning.</p>
                    </div>
                    <div class="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div class="my-4 flex flex-col items-center">
                        <div className='flex bg-[#F2B8EC] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ImCogs color='white' size={28} />
                            </div>
                            <h3 class="text-2xl font-bold md:text-xl">Sit back & relax</h3>
                        </div>
                        <p class="text-gray-700 text-center">Once you accept our quote we will arrange transport of your vehicle to the specified reconditioner.</p>
                    </div>



                </div>
            </section>

        </>
    )
}

export default Howitworks