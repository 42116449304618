import LandingPage from './screens/landing-page';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tearms from './screens/terms';
import PrivacyPolicy from './screens/privacyPolicy';


function App() {
  return (
    <Router>
     <Routes>
       <Route path='/' element={<LandingPage/>}/>
       <Route path='/terms-and-conditions' element={<Tearms/>}/>
       <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
     </Routes>
   </Router>
  );
}

export default App;
